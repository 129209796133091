import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {isMobile} from "react-device-detect";
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "./locales/en-us.json";
import ptBR from "./locales/pt-br.json";
import esES from "./locales/es-es.json";
import ptPT from "./locales/pt-pt.json";
import {useLogout} from "../../utils/logout/logout";
import SidePanel from "../../components/side/SidePanel";
import {Button} from "mfit-components";
import primaryTop from "../../assets/img/primaryTop.png";
import userFemale1 from "../../assets/img/userFemale1.jpeg";
import defaultBottom from "../../assets/img/defaultBottom.png";
import primaryBottom from "../../assets/img/primaryBottom.png";
import defaultTop from "../../assets/img/defaultTop.png";
import clientFemale1 from "../../assets/img/clientFemale1.jpg";

const Logout = () => {
    const {type} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {logout} = useLogout();
    useComponentTranslations('logout', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});

    const login = () => {
        window.location.href = "reset://";
        navigate("/");
    }

    const handleClearCache = () => {
        window.location.href = "reset://";
    }

    useEffect(() => {
        logout();
    }, [logout]);

    return (
        <div className={'grid xl:grid-cols-2 h-dvh'}>
            <SidePanel
                className={"hidden xl:block"}
            >
                <img
                    className={"w-full h-full object-cover"}
                    src={type === 'user' ? userFemale1 : clientFemale1}
                    alt={type === 'user' ? 'user female' : 'client female'}
                />
            </SidePanel>
            <div className={'flex flex-col justify-center items-center w-full'}>
                <img
                    src={type === 'user' ? defaultTop : primaryTop}
                    alt={type === 'user' ? 'Default Top' : 'Primary Top'}
                    className="absolute right-0 top-0 w-min xl:hidden -z-10"
                />
                <div className={'w-full p-2 items-center'}>
                    <div className={'flex flex-col items-center'}>
                        <img
                            className={"max-w-[320px]"}
                            alt={"logo"}
                            src={`https://cdn.mfitpersonal.com.br/assets/logo/logo-mfit-horizontal-azul.png`}
                        />
                    </div>
                    <div className={'flex flex-col text-center items-center p-1'}>
                            <span className={'font-semibold'}>
                                {t("logout:youLoggedOut")}
                            </span>
                        <span className={'mt-2'}>
                                {t("logout:loggedOutMessage")}
                            </span>
                        <Button
                            variant={type === "user" ? "default" : "primary"}
                            size={"btn"}
                            className={'mt-4 max-w-80'}
                            onClick={login}>{t("logout:loginAgain")}
                        </Button>
                        {
                            isMobile &&
                            <Button
                                variant={"link"}
                                className={'mt-3 text-xs'}
                                onClick={handleClearCache}>
                                {t("logout:deleteCache")}
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <img
                src={type === 'user' ? defaultBottom : primaryBottom}
                alt={type === 'user' ? 'Default Bottom' : 'Primary Bottom'}
                className="fixed bottom-0 left-0 w-min xl:hidden lg:block -z-10"
            />
        </div>
    )
}

export default Logout;