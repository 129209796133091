import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import ptBR from './locales/pt-br.json';
import enUS from './locales/en-us.json';
import esES from './locales/es-es.json';
import ptPT from './locales/pt-pt.json'
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import {getAuthentication, getLoading} from "../../store/authentication/authenticationSelector";
import {authenticate, setAuthentication} from "../../store/authentication/authenticationReducer";
import {Button, ErrorMessageInput, PasswordInput, WarningMessage} from "mfit-components";

const LoginForm = ({setShowSignUpWarning, type}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authentication = useSelector(getAuthentication);
    const loading = useSelector(getLoading);
    useComponentTranslations('loginForm', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});

    const handleTryAgain = () => {
        dispatch(setAuthentication(0));
    }

    const handleShowSignUpClick = () => {
        if (type === "client")
            setShowSignUpWarning(true);
        else
            navigate('/signup');
    }

    const handleForgotPasswordClick = () => {
        navigate(`/recover-password/${type}`);
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t("loginForm:invalidEmailValidation"))
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("loginForm:invalidEmailValidation"))
            .required(t("loginForm:requiredEmailValidation")),
        password: Yup.string().required(t("loginForm:requiredPasswordValidation"))
    });

    const initialValues = {
        email: '',
        password: '',
        type: type
    };

    const handleSubmit = async (values) => {
        dispatch(authenticate(values))
    }

    return (
        <>
            {
                authentication === 0 &&
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur={true}
                    validateOnChange={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        handleSubmit(values).catch(() => {
                            resetForm();
                        })
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({errors, touched}) => (
                        <>
                            <Form>
                                <Field name="email" className={"custom-form"} type="email" placeholder={t("loginForm:placeHolderEmail")}/>
                                {
                                    errors.email && touched.email &&
                                    <ErrorMessageInput className={'text-red-danger-500'}>{errors.email}</ErrorMessageInput>
                                }
                                <div className="mb-1">
                                    <Field name={"password"}>
                                        {({
                                              field,
                                              form: {setFieldValue, setFieldTouched}
                                          }) => (
                                            <div>
                                                <PasswordInput
                                                    placeholder={t("loginForm:placeHolderPassword")}
                                                    value={field.value}
                                                    onChange={(value) => setFieldValue(field.name, value)}
                                                    onBlur={() => setFieldTouched(field.name, true)}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                    {errors.password && touched.password &&
                                        <ErrorMessageInput className={'text-red-danger-500'}>{errors.password}</ErrorMessageInput>}
                                </div>
                                <div className='flex flex-row justify-center pb-3 pt-1'>
                                    <span className={'font-semibold text-sm cursor-pointer'}>
                                        {t("loginForm:forgotPassword")}&nbsp;
                                        <span
                                            className={"underline"}
                                            onClick={handleForgotPasswordClick}>
                                            {t("loginForm:clickHere")}
                                        </span>
                                    </span>
                                </div>
                                <Button disabled={loading}
                                        variant={type === 'user' ? 'default' : 'primary'}
                                        type="submit"
                                        size={"btn"}>
                                    {loading ? t("loginForm:wait") : t("loginForm:signin")}
                                </Button>
                            </Form>
                            <div className="mt-1 pb-2">
                                <Button
                                    variant={type === 'user' ? 'outline-default' : 'outline-primary'}
                                    size={"btn"}
                                    onClick={handleShowSignUpClick}>
                                    {t("loginForm:iDontHaveAnAccount")}
                                </Button>
                            </div>
                            <div className="text-center text-sm">
                                <a href="https://termosdeuso.mfitpersonal.com.br/" rel="noreferrer"
                                   target="_blank">{t("loginForm:termsOfService")}</a>
                            </div>
                        </>
                    )}
                </Formik>
            }
            {
                authentication === 2 &&
                <div className={'flex flex-col items-center justify-center'}>
                    <WarningMessage
                        icon={"fa-regular fa-circle-exclamation text-red-danger-500"}
                        title={t("loginForm:invalidEmailOrPassword")}
                        subtitle={`${t("loginForm:invalidEmailOrPasswordText1")} ${t("loginForm:invalidEmailOrPasswordText2")}`}
                        className={'pt-1 pb-2 text-center'}
                    />
                    <div className={'pt-2 pb-2 text-center'}>
                        <Button
                            variant={type === 'user' ? 'default' : 'primary'}
                            size={"btn"}
                            onClick={handleTryAgain}>
                            {t("loginForm:tryAgain")}
                        </Button>
                        <Button
                            className={'mt-2'}
                            variant={type === 'user' ? 'outline-default' : 'outline-primary'}
                            size={"btn"}
                            onClick={handleForgotPasswordClick}>
                            {t("loginForm:iForgotMyPassword")}
                        </Button>
                    </div>
                </div>
            }
        </>
    );
}
export default LoginForm;