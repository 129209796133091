import Cookies from "js-cookie";

export const apiUrl = "https://api.mfitpersonal.com.br";
export const appVersion = 32;

export const getClientToken = () => {
    return Cookies.get("client_token");
}
export const getUserToken = () => {
    return Cookies.get("user_token");
}